'use strict';

import { ILogService, IScope } from "angular";
import { ERoutingServiceProvider, EServiceStatus, RoutingSettings } from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require("./admin.route.settings.component.scss")

export default class AdminRoutingSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.route.settings.component.html');
    this.scope = {
    };
    this.controller = AdminRoutingSettingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminRoutingSettingsComponentController {
  public settings: RoutingSettings;
  public isLoading: boolean = false;
  public statusCls = 'admin-route-settings-info-question';

  //TODO Add here more if available
  public availableProviders = [ERoutingServiceProvider.NONE, ERoutingServiceProvider.VALHALLA];

  constructor(private $scope: IScope, private $log: ILogService, private restService: RestService, private Notification) {
    this.load();
  }

  save() {
    this.isLoading = true;
    this.restService.saveAdminRoutingSettings(this.settings)
      .then(result => {
        this.settings = result;
      }).catch(err => {
        this.$log.error(err);
        this.Notification.error({
          message: err.message
        });
      }).finally(() => {
        this.isLoading = false;
        this.updateCss();
        this.$scope.$applyAsync();
      });
  }

  load() {
    this.isLoading = true;
    this.restService.getAdminRoutingSettings()
      .then(settings => {
        this.settings = settings;
      }).catch(err => {
        this.$log.error(err);
        this.Notification.error({
          message: err.message
        });
      }).finally(() => {
        this.isLoading = false;
        this.updateCss();
        this.$scope.$applyAsync();
      });
  }

  updateCss(): void {
    switch (this.settings.status.status) {
      case EServiceStatus.NOT_CONFIGURED:
        this.statusCls = 'admin-route-settings-info-question';
        break;
      case EServiceStatus.UNKNOWN:
        this.statusCls = 'admin-route-settings-info-unknown';
        break;
      case EServiceStatus.OFFLINE:
        this.statusCls = 'admin-route-settings-info-offline';
        break;
      case EServiceStatus.ONLINE:
        this.statusCls = 'admin-route-settings-info-online';
        break;
    }
  }
}